import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 60px;
`;

export const Main = styled.main`
  /* @media (max-width: 1439px) {
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    margin-top: 88px;
  } */
`;

export const IconWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  left: 30px;
  @media (max-width: 767px) {
    bottom: 0px;
    left: 0px;
  }
`;
