import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Styled from './styled';
import * as UI from '../';

const Agreement = () => {
  const [isAgreementVisible, setAgreementVisible] = useState(false);

  useEffect(() => {
    const agreementAccepted = localStorage.getItem('agreementAccepted');
    if (!agreementAccepted) {
      setAgreementVisible(true);
    }
  }, []);

  const handleAgreeClick = () => {
    localStorage.setItem('agreementAccepted', 'true');
    setAgreementVisible(false);
  };

  if (!isAgreementVisible) {
    return null;
  }

  return (
    <Styled.Agreement>
      <UI.Paragraph size={'big'}>
        This website uses cookies to ensure you get the best experience on our
        website. View our{' '}
        <Styled.LinkWrapper as={Link} to={`/terms`}>
          Privacy Policy
        </Styled.LinkWrapper>{' '}
        for more information.
      </UI.Paragraph>
      <UI.Button type={'secondary'} onClick={handleAgreeClick}>
        got it
      </UI.Button>
    </Styled.Agreement>
  );
};

export default Agreement;
