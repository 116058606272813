import styled from 'styled-components';
import { COLORS } from '../../models/colors';
import { Link } from 'react-router-dom';

export const Agreement = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 10px;
  background-color: ${COLORS.RICH_BLACK};
  width: 375px;
  p {
    color: ${COLORS.LIGHT};
  }
  button {
    width: 100%;
  }
`;

export const LinkWrapper = styled(Link)`
  color: ${COLORS.PURPLE_BRIGHT};
  text-decoration: none;
`;
